import React, { useState } from "react";
import ForgotPassComponent from "../../components/forgotpass";
import AuthApi from "../../api/auth";
import { useSelector } from "react-redux";

const ForgotPass = ({
  isAdmin,
  status = "",
  onSubmit,
}) => {
  const [sendEmailStatus, setSendEmailStatus] = useState("initial");

  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const userStatus = useSelector((state) => state.user.status);

  const handleSendPasswordResetEmail = async (email) => {
    setSendEmailStatus("loading");
    
    const data = await AuthApi.sendEmailResetPassword(email);
    if(data.status !== 200) {
      setSendEmailStatus("error");
    }else {
      setSendEmailStatus("success");
    }
  };

  return (
    <>
      {!isAuthenticated && userStatus !== "loading" && (
        <ForgotPassComponent
          status={status}
          isAdmin={isAdmin}
          sendEmailStatus={sendEmailStatus}
          onSubmit={handleSendPasswordResetEmail}
        />
      )}
    </>
  );
};

export default ForgotPass;
